import React from 'react';
import { Login as LoginForm } from '../../src/components/login';

import { Layout } from 'gatsby-theme-core/src/components';

export const Login = () => (
  <Layout>
    <LoginForm />
  </Layout>
);

export default Login;
