import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { DefaultComponentProps } from 'gatsby-theme-core/src/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { useAuth } from 'gatsby-plugin-auth';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Grid, Typography, Button, TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Container } from 'gatsby-theme-core/src/components';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
    },
    textField: {
      width: 'inherit',
      borderRadius: '4px',
    },
  }),
);

export type LoginProps = DefaultComponentProps;

export const Login = ({ ...props }: LoginProps) => {
  const { login } = useAuth();
  const [state, setState] = useState({ password: ``, show: false });
  const classes = useStyles({});

  const submit = (event: any) => {
    event.preventDefault();
    login(state);
    navigate(`/`);
  };

  const handleChange = prop => event => {
    setState({ ...state, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setState({ ...state, show: !state.show });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <Container classname="secondary-main">
      <Grid className={classes.root} container direction="column" alignItems="center" justify="center">
        <form noValidate autoComplete="off" method="post" onSubmit={submit}>
          <Grid item xs>
            <Typography variant="h1" className="white-text center">
              Alliance <div className="primary-main-text">Capital</div>
            </Typography>
          </Grid>
          <Grid container item xs={12}>
            <TextField
              id="password"
              label="Password"
              type={state.show ? 'text' : 'password'}
              className={clsx(classes.textField, 'secondary-light my-2')}
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      className="white-text"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.show ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              variant="filled"
              autoFocus
            />
          </Grid>
          <Grid container item xs={12}>
            <Button type="submit" variant="contained" className="primary-main white-text py-2" fullWidth>
              Login
            </Button>
          </Grid>
        </form>
      </Grid>
    </Container>
  );
};

export default Login;
